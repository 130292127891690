import './App.css';
import Short from './content/short';

function App() {
	return (
		<>
			<Short />
		</>
	);
}

export default App;
